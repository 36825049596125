import React from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import Privacy from "components/Privacy/Privacy"

const PrivacyPage = () => (
  <MainTemplate title="Arkeo - Privacy">
    <Privacy />
  </MainTemplate>
)

export default PrivacyPage

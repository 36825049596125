import React from "react"
import styled from "styled-components"
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'

import manImage from 'assets/images/home/arkeo-man-clap-hands.png'

const MainTitle = styled.h1`
  color: ${({ theme }) => theme.colors.fonts.lightGreen};
  font-size: 10rem;
  font-family: ${({ theme }) => theme.font.family.bebas};
  margin: 0.33em 0;

  @media(max-width: 894px) {
		font-size: 100px;
    line-height: 100px;
  }

  @media(max-width: 480px) {
		font-size: 70px;
    line-height: 70px;
  }

  @media(max-width: 370px) {
    margin-top: 100px;
  }
`

const Title = styled.h3`
	color: white;
	font-size: 40px;
	font-family: ${({ theme }) => theme.font.family.bebas};
	font-weight: 400;
`

const Description = styled.p`
	font-family: ${({ theme }) => theme.font.family.circular};
	color: white;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0px;
  max-width: 700px;
  margin-top: 0;
  margin-bottom: 80px;
`

const Image = styled.div`
  background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, ${({ theme }) =>
  theme.colors.background.dark
} 90%), url(${manImage});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 200px 100px 0 100px;

  @media(max-width: 894px) {
		padding: 200px 30px 0 30px;
  }

  @media(max-width: 445px) {
		padding: 0 30px 0 30px;
    background-position-y: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const Content = styled.main`
  flex: 1 1;
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 1rem;
`

const Divider = styled.div`
  height: 200px; 
`

const PrivacyTermsTemplate = ({ showExtraSection, title, children }) => (
	<Wrapper>
    <Header />
    <Content>
      <ContentWrapper>
        <section>
          <Image>
            <MainTitle>
              {title}
            </MainTitle>
            {
              showExtraSection ?
                <>
                  <Title>
                    DATE: 29 AUGUST 2019
                  </Title>
                  <Description>
                    Please read these terms and conditions (Terms) carefully before you access our mobile application or purchase our application through a third-party app store. These Terms tell you who we are, contain the terms and conditions on which we supply
                    digital content and services to you through our application or via other delivery methods to you.
                  </Description> 
                </>
                : <Divider />
            }
          </Image>
        </section>
        {children}
      </ContentWrapper>
    </Content>
    <Footer />
  </Wrapper>
)

export default PrivacyTermsTemplate

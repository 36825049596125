import React from "react"
import { Link } from 'gatsby'
import styled from "styled-components"
import PrivacyTermsTemplate from 'components/PrivacyTermsTemplate'

const TextContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;

  @media(max-width: 894px) {
		padding: 0 30px;
  }
`

const Text = styled.p`
	font-family: ${({ theme }) => theme.font.family.circular};
	color: white;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0px;
`

const PrivacyLink = styled(Link)`
  color: white;

  &:hover {
    color: ${({ theme }) => theme.colors.fonts.lightGreen};
  }
`

const PrivacySection = () => (
	<section>
    <TextContainer>
    <Text>1. WHO WE ARE</Text>
    <Text>
      My Arkeo Ltd is a company registered in England
      and Wales (company number 11591732) with a registered
      office at 4a Petherton Road, London, United Kingdom, N5 2RD.
    </Text>
    <Text>
      When you use our services, you’ll share some information with us.
      We want to be upfront about the information we collect, how we use it,
      who we share it with and the choices we give you to control, access and
      update your information. For the purposes of data protection legislation,
      we are the data controller of your personal data. We are registered with the
      Information Commissioners Office in the UK with reference number <b>A8485743</b> 
    </Text>
    <Text>
      We are committed to protecting and respecting your privacy. This notice sets out the
      basis on which any personal data we collect from you, or that you provide us, will be
      processed by us. Please read this privacy notice carefully. 
    </Text>
    <Text>
      Questions, comments and requests regarding this privacy notice are welcomed and should be
      addressed to Jana Dowling or emailed to us at: hello@myarkeo.com.
    </Text>

    <Text>2. SUMMARY</Text>
    <Text>
      2.1. We keep to a minimum the information we hold about you;
    </Text>
    <Text>
      2.2. We use your data to provide our services to you, respond to
      your enquiries, manage our relationship with you, meet our legal obligations, and improve our mobile application;
    </Text>
    <Text>
      2.3. We delete your data when it is no longer needed for these things;
    </Text>
    <Text>
      2.4. Generally, we do not give your information to third parties, but there are some exceptions;
    </Text>
    <Text>
      2.5. You have lots of privacy rights;
    </Text>
    <Text>
      2.6. We take security seriously;
    </Text>
    <Text>
      2.7. We are happy to answer your questions about any of this.
    </Text>

    <Text>
      3. THE PERSONAL INFORMATION WE COLLECT
    </Text>
    <Text>
      We ensure that your privacy is respected at all times and our
      primary goal is to improve upon and make sure our services and messaging
      are relevant for all our users, while also ensuring that personal information
      of all users is respected and protected.
    </Text>
    <Text>
      This privacy notice applies to the personal data we collect about you through the
      Arkeo mobile application (the App), our website (<PrivacyLink to="https://www.myarkeo.com/">www.myarkeo.com</PrivacyLink>), by telephone,
      or when you otherwise communicate with us. To learn more about the way these third parties
      handle your personal data, please see their respective privacy policies.
    </Text>
    <Text>
      When you download the App, you will be required to create an account in order to access our service.
      If you proceed to create an account, you will be required to provide certain personal information to us in accordance with this privacy notice, in particular:
    </Text>
    <Text>
      <b>Identity data: </b> your first name and last name, title and gender.
    </Text>
    <Text>
      <b>Contact information: </b>your email address and phone number.
    </Text>
    <Text>
      <b>Profile data: </b>your username and password, and any interests communicated to us to enable the personalisation of services and any feedback.
    </Text>
    <Text>
      <b>Health data: </b>including any medication you take and symptoms
    </Text>
    <Text>
      <b>Marketing and communications data: </b>your preferences in receiving marketing from us and our third parties
      and your communication preferences.
    </Text>
    <Text>
      <b>Third-party integration: </b>our website connects directly with third-party websites
      such as the NHS so that we can better understand the use of our website, and to show you relevant content, including ads.
    </Text>
    <Text>
      <b>Billing information: </b>we require you to provide your billing details, a name, address, email address and financial information
      corresponding to your selected method of payment (e.g. a credit card number and expiration date or a bank account number).
      We use a third-party payment gateway to collect, store and process billing information. We do not store this information and all
      payment pages use TLS technology.
    </Text>

    <Text>
      4. HOW WE USE YOUR PERSONAL INFORMATION
    </Text>
    <Text>
      We must have a legal basis for processing your personal data. We consider that we have a legal basis where:
    </Text>
    <Text>
      4.1. you have given us your consent to do so for specific purposes which we have told you about; 
    </Text>
    <Text>
      4.2. to fulfil our contractual responsibility to deliver the services to you through our App;
    </Text>
    <Text>
      4.3. to pursue our legitimate interests of providing support and improving the services we offer and developing new service features;
    </Text>
    <Text>
      4.4. to comply with a legal obligation; and/or
    </Text>
    <Text>
      4.5. the law otherwise permits or requires it.
    </Text>
    <Text>
      We have set out below a list of all the ways we may use your personal data and which of the legal bases we rely on to do so. We have
      also identified what our legitimate interests are, where appropriate.
    </Text>
    <Text>
      In some cases we may use more than one legal basis for processing your personal data; this will depend on the specific purpose for which we are using your personal data.
      Please contact us if you have any queries about the specific legal basis that we rely on for processing your personal data.
    </Text>

    {/* TABLE TODO */}

    <Text>
      5. SHARING AND TRANSFERRING YOUR PERSONAL INFORMATION
    </Text>
    <Text>
      We may share your personal information with the third parties
      listed in the table below for the reasons specified to enable us to provide our services. 
    </Text>
    <Text>
      Your personal information may be stored and processed in any country where we engage service
      providers, including outside the EEA. Whilst these counties will have data protection rules that
      differ from the EEA, we will take measures to ensure that any such transfers comply with applicable
      data protection laws and that your personal information remains protected to the standards described in this privacy notice.
    </Text>

    {/* TABLE TODO */}

    <Text>
      We may also share anonymised information with third parties for learning purposes. This will not contain any personal information
      that is able to identify you as an individual.
    </Text>
    <Text>
      If you would like further information about who we share your personal information with,please contact us at hello@myarkeo.com.
    </Text>

    <Text>
      6. RETAINING YOUR PERSONAL INFORMATION
    </Text>
    <Text>
      We will keep your personal information for no longer than is necessary for the purposes for which it was obtained. The criteria for
      determining the duration for which we will retain your personal information is as follows:
    </Text>
    <Text>
      6.1. We will retain your personal information in a form that permits identification only for as long as:
    </Text>
    <Text>
      6.1.1. we maintain an ongoing relationship with you; or
    </Text>
    <Text>
      6.1.2. your personal information is necessary in connection with the lawful purposes set out in this
      privacy notice for which we have a valid legal basis;
    </Text>
    <Text>
      6.2. The duration of:
    </Text>
    <Text>
      6.2.1. any applicable limitation period under applicable law (i.e. where we are required by law to keep your personal information); or
    </Text>
    <Text>
      6.2.2. 24 months following inactivity of your account.
    </Text>
    <Text>
      6.3. If any relevant legal claims are brought, we may continue to process your personal data for such additional periods as are necessary
      in connection with that claim.
    </Text>
    <Text>
      During the periods specified in paragraphs 2(i) and 2(ii) above, we will restrict our processing of your personal information to the storage of, and maintaining the security of,
      that data, except to the extent that data needs to be reviewed in connection with any legal claim or obligation under applicable law.
    </Text>
    <Text>
      After this period your personal information will be anonymised so that you are no longer identified or identifiable from such
      information, or securely deleted/destroyed.
    </Text>
    <Text>
      We may retain personal information about you for statistical purposes. Where data is retained for statistical purposes it will always be anonymised,
      meaning that you will not be identifiable from that data.
    </Text>

    <Text>
      7. KEEPING YOUR PERSONAL INFORMATION SECURE
    </Text>
    <Text>
      We have appropriate security measures in place to prevent personal information from
      being accidentally lost or used or accessed in an unauthorised way. 
    </Text>
    <Text>
      We limit access to your personal information to those who have a genuine business need to know it. Those processing your information will do so only in
      an authorised manner and are subject to a duty of confidentiality.
    </Text>
    <Text>
      We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.
    </Text>
    <Text>
      If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems,
      please visit <PrivacyLink to="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">www.getsafeonline.org</PrivacyLink>. Get Safe Online is supported by HM Government and leading businesses.
    </Text>

    <Text>
      8. COMMUNICATIONS
    </Text>
    <Text>
      This section explains how we will ensure that you only receive communications that you wish to receive. 
    </Text>
    <Text>
      <b>Marketing communications:</b>
    </Text>
    <Text>
      We can only use your personal information to send you marketing messages if we have either your consent or it is a ‘legitimate interest’.
      A ‘legitimate interest’ is when we have a business or commercial reason to use your information
    </Text>
    <Text>
      The personal information we hold about you is made up of what you tell us and the data we collect about you when you use our services, or data provided to us from third parties we work with. We study this to form a view on what we think you may want or need,
      or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you.
    </Text>
    <Text>
      By consenting to receive additional communications (by telephone, or email) from us and any named third parties that feature at the point of obtaining consent in respect of such information,
      we will process your personal data in accordance with this privacy notice. 
    </Text>
    <Text>
      If you have provided your consent to receive marketing communications from us and you change your mind, you can change your preferences and unsubscribe at any time by unsubscribing from the relevant communication channel or by contacting us at hello@myarkeo.com. If you choose not to receive this information,
      we will be unable to keep you informed of new products, services and promotions that may interest you.
    </Text>
    <Text>
      <b>Service communications:</b>
    </Text>
    <Text>
      We may send you communications such as those which relate to any service updates (e.g. App updates or enhanced features) or provide customer satisfaction surveys. We consider that we can lawfully send these communications to you as we have a legitimate
      interest to do so, namely to effectively provide you with the best service we can and to grow our business.
    </Text>

    <Text>
      8. CONTROL OVER YOUR PERSONAL INFORMATION
    </Text>
    <Text>
      Under the General Data Protection Regulation, you have a number of important rights available to you for free.
      In summary, those include rights to:
    </Text>
    <Text>
      8.1. be informed about how your personal information is being used (hopefully this privacy notice explains
      this in sufficient detail but if not, please contact us);
    </Text>
    <Text>
      8.2. access the personal information we hold about you;
    </Text>
    <Text>
      8.3. request that we port elements of your data to another service provider;
    </Text>
    <Text>
      8.4. request us to correct any mistakes in your information which we hold;
    </Text>
    <Text>
      8.5. request the erasure of personal information concerning you in certain situations;
    </Text>
    <Text>
      8.6. receive the personal information concerning you which you have provided to us, in a structured format;
    </Text>
    <Text>
      8.7. stop any direct marketing which you can do through your account or the unsubscribe links at the bottom of emails;
    </Text>
    <Text>
      8.8. object to decisions being taken by automated means concerning you or significantly affect you.
    </Text>
    <Text>
      For further information on each of these rights, including the circumstances in which they apply, see the
      <PrivacyLink to="http://www.getsafeonline.org">https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</PrivacyLink>
    </Text>
    <Text>
      If you would like to exercise any of these rights, please:
    </Text>
    <Text>
      8.9. email us at hello@myarkeo.com;
    </Text>
    <Text>
      8.10. let us have information to identify you;
    </Text>
    <Text>
      8.11. let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill); and
    </Text>
    <Text>
      8.12. let us know the information to which your request relates.
    </Text>

    <Text>
      9. HOW TO COMPLAIN
    </Text>
    <Text>
      We hope that we can resolve any query or concern you raise about our use of your information. If you are not happy with how we manage your personal data, you have the right to lodge a complaint with a supervisory authority.
      The supervisory authority in the UK is the Information Commissioner who may be contacted at <PrivacyLink to="https://ico.org.uk/concerns/">https://ico.org.uk/concerns/</PrivacyLink>.
    </Text>

    <Text>
      10. CHANGES TO THIS PRIVACY NOTICE
    </Text>
    <Text>
      This privacy notice was published on 29 August 2019] and last updated on 29 August 2019. 
    </Text>

    <Text>
      11. HOW TO CONTACT US
    </Text>
    <Text>
      Please contact us if you have any questions about this privacy notice or the information we hold about you.
    </Text>
    <Text>
      If you wish to contact us please send an email to hello@myarkeo.com.
    </Text>
    </TextContainer>
  </section>
)

export default PrivacySection

import React from "react"
import PrivacyTermsTemplate from 'components/PrivacyTermsTemplate'
import PrivacySection from './PrivacySection'

const Privacy = () => (
	<PrivacyTermsTemplate title="ARKEO PRIVACY">
    <PrivacySection />
  </PrivacyTermsTemplate>
)

export default Privacy
